import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    console.log(
      `%c
 _    __          __  _ __          __
| |  / /__  _____/ /_(_) /_  __  __/ /__
| | / / _ \\/ ___/ __/ / __ \\/ / / / / _ \\
| |/ /  __(__  ) /_/ / /_/ / /_/ / /  __/
|___/\\___/____/\\__/_/_.___/\\__,_/_/\\___/
  `, 'color: #d71920; font-family: monospace'
    );

    console.log("Crafted with ♥️ by the team at Data Sciences");
  }
}
