import { Controller } from "@hotwired/stimulus"
import Konami from 'konami'

export default class extends Controller {
  static targets = [ 'html', 'laurier', 'glasses' ]


  connect() {
    const easterEgg = new Konami(() => {
      this.annimateLaurier();
    })
  }

  annimateLaurier() {
    console.log("Join the Laurier Club!");
    this.laurierTarget.classList.remove("hidden")
    this.glassesTarget.classList.remove("hidden");
    this.laurierTarget.classList.add("laurier")

    setTimeout(() => {
      // Calculate the distance to fall
      const glassesRect = this.glassesTarget.getBoundingClientRect();
      const laurierRect = this.laurierTarget.getBoundingClientRect();

      // console.log(glassesRect.height);

      const fallDistance = laurierRect.top - glassesRect.top;

      // console.log(fallDistance);

      // Set the CSS variable for the fall distance
      this.glassesTarget.style.setProperty('--fall-distance', `${fallDistance}px`);

      // Add the class to trigger the animation
      this.glassesTarget.classList.add("glasses");

      // Trigger reflow to ensure the element is visible before animating
      this.glassesTarget.offsetHeight;

      // Add the class to trigger the sunglasses animation
      this.glassesTarget.classList.add("glasses");
    }, 1000);
  }
}