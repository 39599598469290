import { Controller } from "@hotwired/stimulus"
import "ninja-keys"

const list = []; 

export default class extends Controller {

  connect() {
    list.push(
      {
        id: 'Reports',
        title: 'Reports',
        hotkey: 'ctrl+d, cmd+d',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-brand dark:text-brand-dark ninja-icon"><path fill-rule="evenodd" d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm4.5 7.5a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0v-2.25a.75.75 0 0 1 .75-.75Zm3.75-1.5a.75.75 0 0 0-1.5 0v4.5a.75.75 0 0 0 1.5 0V12Zm2.25-3a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0V9.75A.75.75 0 0 1 13.5 9Zm3.75-1.5a.75.75 0 0 0-1.5 0v9a.75.75 0 0 0 1.5 0v-9Z" clip-rule="evenodd" /></svg>`,
        section: 'Pages',
        handler: () => {
          Turbo.visit("/reports")
        }
      },
      {
        id: 'Ridings',
        title: 'Ridings',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>`,
        children: ['Ajax'],
        handler: () => {
          // open menu if closed. Because you can open directly that menu from it's hotkey
          ninja.open({ parent: 'Ridings' });
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      }
    )
    
    this.loadRidings()
    setTimeout(() => this.setData(), 1000)
  }

  setData() {
    this.element.data = list;   
  }


  loadRidings() {
    fetch('/riding_search', {
      headers: { accept: 'application/json'}
    }).then((response) => response.json())
    .then(data => { 
     var ridingArray = Object.values(data)[0]
     ridingArray.forEach(riding => {
      list.push(this.ridingTemplate(riding))
    });
   });  
  } 

  disconnect() {
    list.length = 0;
  }


  ridingTemplate(riding) {
    return {
      id: `${riding.name_en}`,
      title: `${riding.riding_code} - ${riding.name_en}`,
      icon: `<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>`,
      parent: 'Ridings',
      handler: () => {
        Turbo.visit(`/ridings/${riding.riding_code}`)
      }
    }
  }


}